'use client';

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

interface ScrollContextProps {
  scrollDirection: number;
}

interface ScrollProviderProps {
  children: ReactNode;
}

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined);

export const useScrollContext = (): ScrollContextProps => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScrollContext must be used within a ScrollProvider');
  }
  return context;
};

export enum Direction {
  UP = -1,
  DOWN = 1,
}

export const ScrollProvider: React.FC<ScrollProviderProps> = ({ children }) => {
  const [scrollDirection, setScrollDirection] = useState<Direction>(
    Direction.DOWN,
  );
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY) {
        setScrollDirection(Direction.UP);
      } else if (currentScrollY > lastScrollY) {
        setScrollDirection(Direction.DOWN);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const contextValue: ScrollContextProps = {
    scrollDirection,
  };

  return (
    <ScrollContext.Provider value={contextValue}>
      {children}
    </ScrollContext.Provider>
  );
};
