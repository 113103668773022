import BookOpenSvg from '@/assets/icons/general/new/book-open.svg';
import InfoSvg from '@/assets/icons/general/new/info.svg';

interface NoticeBandProps {
  referId: string;
  title: string;
  description: string;
}

export function NoticeBand({ referId, title, description }: NoticeBandProps) {
  return (
    <div id={referId} className="py-40 px-24 w-full bg-display-200">
      <div className="flex flex-col pb-48 mx-auto w-full max-w-[1280px] xs:text-center border-b border-display-400">
        <h2
          className="mb-24 heading-5"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />

        <div className="flex flex-col xs:flex-row justify-center">
          <a
            href="https://storage.googleapis.com/public-assets-stg/site/seguro-de-vida/SV_Condicoes_Gerais_Seguro_de_Vida_Stone.pdf"
            className="flex items-center mb-16 xs:mb-0 font-semibold text-stone-500 paragraph-18"
          >
            <InfoSvg className="mr-10" />
            Condições Gerais
          </a>
          <div className="hidden xs:block mx-16 border border-display-400" />
          <a
            href="https://storage.googleapis.com/public-assets-stg/site/seguro-de-vida/SV_Manual_do_Segurado_-_Seguro_de_Vida_Stone.pdf"
            className="flex items-center font-semibold text-stone-500 paragraph-18"
          >
            <BookOpenSvg className="mr-10" />
            Manual do Segurado
          </a>
        </div>
      </div>
      <p
        className="mx-auto mt-48 w-full max-w-[1280px] paragraph-14"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </div>
  );
}
