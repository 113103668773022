import Image from 'next/image';

import { ResponsiveImagesWithAttributes } from '@/types/cms';

const CompanyPartnersScrollingTicker = ({
  leftTicker,
  rightTicker,
}: {
  leftTicker: ResponsiveImagesWithAttributes[];
  rightTicker: ResponsiveImagesWithAttributes[];
}) => {
  return (
    <div className="group flex overflow-hidden gap-[24px] w-[264px] sm:w-[256px] lg:w-[419px] h-[306px] sm:h-[372px]">
      <div className="group flex overflow-hidden flex-col gap-[16px] lg:gap-[24px]">
        <div className="flex flex-col gap-20 animate-loop-ticker">
          {leftTicker.map(item => (
            <div
              className={`flex justify-center items-center lg:w-[197px] h-[70px] md:h-[104px] text-center bg-display-0 rounded ${item.className}`}
              key={item.image.alternativeText}
            >
              <Image
                src={item.image.path}
                width={item.width}
                height={item.height}
                alt={item.image.alternativeText}
                layout="intrinsic"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="group flex overflow-hidden flex-col gap-[16px] lg:gap-[24px]">
        <div className="flex flex-col gap-20 animate-loop-ticker-reverse">
          {rightTicker.map(item => (
            <div
              className={`flex justify-center items-center lg:w-[197px] h-[70px] md:h-[104px] text-center bg-display-0 rounded ${item.className}`}
              key={item.image.alternativeText}
            >
              <Image
                src={item.image.path}
                width={item.width}
                height={item.height}
                alt={item.image.alternativeText}
                layout="intrinsic"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyPartnersScrollingTicker;
