import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import { CardImageProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';

type HorizontalCardsProps = {
  referId: string;
  title: string;
  description: string;
  horizontalCards: CardImageProps[];
  buttons?: ButtonProps[];
  link?: string;
  linkLabel?: string;
};

export function HorizontalCards({
  referId,
  title,
  horizontalCards,
  description,
  buttons,
  link,
  linkLabel,
}: HorizontalCardsProps) {
  return (
    <div
      id={referId}
      className="flex flex-col gap-24 md:gap-40 justify-center items-center py-40 lg:py-80 px-24 2xl:px-0"
    >
      <div className="flex flex-col gap-8 justify-center items-center">
        {title && (
          <h3
            dangerouslySetInnerHTML={{ __html: title }}
            className="font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-9 text-center"
          />
        )}
        {description && (
          <p className="font-sharon text-lg lg:text-xl leading-[26px] lg:leading-5 text-center">
            {description}
            {link && (
              <Link
                href={link}
                target="_blank"
                className="font-sharon text-xl leading-5 text-stone-600 lg:underline"
              >
                {linkLabel}
              </Link>
            )}
          </p>
        )}
      </div>
      <div className="flex md:overflow-x-auto xl:overflow-x-hidden flex-col md:flex-row gap-16 lg:gap-40 justify-center md:justify-start lg:justify-between items-center lg:items-stretch w-full max-w-7xl">
        {horizontalCards.map(
          ({ image, description, title, isHighLight }, index) => (
            <div
              key={index}
              className={classNames(
                'flex flex-col justify-center items-center gap-16 p-24 pb-0 lg:p-40 lg:pb-0 min-w-[321px] w-full lg:w-[400px] bg-gradient-to-t from-[#EFF4F8] to-white rounded-2xl border-2 border-display-200',
                {
                  'border-stone-400 from-[rgba(0,168,104,0.5)] to-[rgba(0,168,104,0.1)]':
                    isHighLight,
                },
              )}
            >
              <div className="flex flex-col gap-8 items-center">
                <p className="md:min-w-[317px] font-display text-2xl lg:text-3xl font-bold leading-6 lg:leading-7 text-display-900">
                  {title}
                </p>
                <p className="text-lg lg:text-xl leading-[26px] lg:leading-5 text-display-900">
                  {description}
                </p>
              </div>
              {image && (
                <div className="flex justify-center items-center w-full h-[160px] lg:h-[290px]">
                  <Image
                    src={image.path}
                    width={320}
                    height={290}
                    alt={image.alternativeText}
                    className="object-contain w-auto h-full"
                  />
                </div>
              )}
            </div>
          ),
        )}
      </div>
      <div className="hidden md:flex px-24">
        {buttons?.map((props, index) => (
          <Button
            key={`${props.label}-${index}`}
            {...props}
            sectionReference={title}
            className="relative w-full md:w-max"
          />
        ))}
      </div>
    </div>
  );
}
