import { AccordionsPanel } from '@/app/components/sections/AccordionsPanel';
import { Assessments } from '@/app/components/sections/Assessments';
import { Banner } from '@/app/components/sections/Banner';
import { BannersCarousel } from '@/app/components/sections/BannersCarousel';
import { BasicBand } from '@/app/components/sections/BasicBand';
import { BestExperience } from '@/app/components/sections/BestExperience';
import { BlackFridayHero } from '@/app/components/sections/BlackFridayHero';
import { CardBanner } from '@/app/components/sections/CardBanner';
import { CheckImages } from '@/app/components/sections/CheckImages';
import { CreditCard } from '@/app/components/sections/CreditCard';
import { DefaultHero } from '@/app/components/sections/DefaultHero';
import { DefaultRTBs } from '@/app/components/sections/DefaultRTBs';
import { Faq } from '@/app/components/sections/Faq';
import { FeaturedImage } from '@/app/components/sections/FeaturedImage';
import { FeatureLine } from '@/app/components/sections/FeatureLine';
import { FeatureRTBs } from '@/app/components/sections/FeatureRTBs';
import { FeaturesBlock } from '@/app/components/sections/FeaturesBlock';
import { FeaturesHero } from '@/app/components/sections/FeaturesHero';
import { FeaturesInfo } from '@/app/components/sections/FeaturesInfo';
import { FeesBanner } from '@/app/components/sections/FeesBanner';
import { Flags } from '@/app/components/sections/Flags';
import { FloatingButton } from '@/app/components/sections/FloatingButton';
import { FloatingCTA } from '@/app/components/sections/FloatingCTA';
import { Footer } from '@/app/components/sections/Footer';
import { FranchiseForm } from '@/app/components/sections/FranchiseForm';
import { Header } from '@/app/components/sections/Header';
import { HeroVideos } from '@/app/components/sections/HeroVideos';
import { HorizontalCards } from '@/app/components/sections/HorizontalCards';
import { IconDescription } from '@/app/components/sections/IconDescription';
import { IconDescriptionCard } from '@/app/components/sections/IconDescriptionCard';
import { IndicatorsCard } from '@/app/components/sections/IndicatorsCard';
import { InfoCardImage } from '@/app/components/sections/InfoCardImage';
import { LinksSection } from '@/app/components/sections/LinksSection';
import { ListCardItems } from '@/app/components/sections/ListCardItems';
import { MutedBanner } from '@/app/components/sections/MutedBanner';
import { MutedRTBs } from '@/app/components/sections/MutedRTBs';
import { NeedHelp } from '@/app/components/sections/NeedHelp';
import { NoticeBand } from '@/app/components/sections/NoticeBand';
import { Partners } from '@/app/components/sections/Partners';
import { PlansCard } from '@/app/components/sections/PlansCard';
import { PrettyVideo } from '@/app/components/sections/PrettyVideo';
import { QRCodeMarksBanner } from '@/app/components/sections/QRCodeMarksBanner';
import { RTB } from '@/app/components/sections/RTB';
import { ScrollingBar } from '@/app/components/sections/ScrollingBar';
import { Simple9x16Video } from '@/app/components/sections/Simple9x16Video';
import { SimpleCard } from '@/app/components/sections/SimpleCard';
import { SlimCard } from '@/app/components/sections/SlimCard';
import { StepByStep } from '@/app/components/sections/StepByStep';
import { StepwiseImages } from '@/app/components/sections/StepwiseImages';
import { TabsCards } from '@/app/components/sections/TabsCards';
import { Testimonials } from '@/app/components/sections/Testimonials';
import { TextDescriptionForm } from '@/app/components/sections/TextDescriptionForm';
import { TitleDescriptionButtonCard } from '@/app/components/sections/TitleDescriptionButtonCard';
import { VerticalTickerBanner } from '@/app/components/sections/VerticalTickerBanner';
import { VideoBanner } from '@/app/components/sections/VideoBanner';
import { VideoCards } from '@/app/components/sections/VideoCards';

export const sectionsMap = {
  'dynamics.assessments': Assessments,
  'dynamics.accordions-pannel': AccordionsPanel,
  'dynamics.card-banner': CardBanner,
  'dynamics.check-images-section': CheckImages,
  'dynamics.default-hero': DefaultHero,
  'dynamics.best-experience': BestExperience,
  'dynamics.feature-line': FeatureLine,
  'dynamics.feature-rtbs': FeatureRTBs,
  'dynamics.faq': Faq,
  'dynamics.features-hero': FeaturesHero,
  'dynamics.features-block': FeaturesBlock,
  'dynamics.features-info': FeaturesInfo,
  'dynamics.fees-banner': FeesBanner,
  'dynamics.floating-button': FloatingButton,
  'dynamics.footer': Footer,
  'dynamics.franchise-form': FranchiseForm,
  'dynamics.header': Header,
  'dynamics.hero-videos': HeroVideos,
  'dynamics.banner-section': Banner,
  'dynamics.icon-description-section': IconDescription,
  'dynamics.list-card-itens': ListCardItems,
  'dynamics.video-cards-section': VideoCards,
  'dynamics.muted-banner': MutedBanner,
  'dynamics.muted-rtbs': MutedRTBs,
  'dynamics.need-help': NeedHelp,
  'dynamics.notice-band': NoticeBand,
  'dynamics.plans-card-section': PlansCard,
  'dynamics.rtb-feature': RTB,
  'dynamics.simple-card': SimpleCard,
  'dynamics.slim-card': SlimCard,
  'dynamics.stepwise-images': StepwiseImages,
  'dynamics.testimonials': Testimonials,
  'dynamics.text-description-form': TextDescriptionForm,
  'dynamics.title-description-button-card': TitleDescriptionButtonCard,
  'dynamics.vertical-ticker-banner': VerticalTickerBanner,
  'dynamics.credit-card': CreditCard,
  'dynamics.horizontal-cards': HorizontalCards,
  'dynamics.video-banner': VideoBanner,
  'dynamics.qr-code-marks-banner': QRCodeMarksBanner,
  'dynamics.info-card-image': InfoCardImage,
  'dynamics.floating-cta': FloatingCTA,
  'dynamics.pretty-video': PrettyVideo,
  'dynamics.step-by-step': StepByStep,
  'dynamics.banners-carousel': BannersCarousel,
  'dynamics.black-friday-hero': BlackFridayHero,
  'dynamics.icon-description-card': IconDescriptionCard,
  'dynamics.default-rtb': DefaultRTBs,
  'dynamics.featured-image': FeaturedImage,
  'dynamics.indicators-card': IndicatorsCard,
  'dynamics.tabs-cards': TabsCards,
  'dynamics.partners': Partners,
  'dynamics.flags': Flags,
  'dynamics.basic-band': BasicBand,
  'dynamics.scrolling-bar': ScrollingBar,
  'dynamics.links-section': LinksSection,
  'dynamics.simple-9x16-video': Simple9x16Video,
};
