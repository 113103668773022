import { twMerge } from 'tailwind-merge';

import Icon, { IconsNames } from '@/components/v3/Icon';

interface FeatureRTBsProps {
  referId: string;
  cards: {
    title: string;
    description: string;
    iconName: IconsNames;
  }[];
  backgroundColor: string;
  cardBackgroundColor: string;
}

export function FeatureRTBs({
  referId,
  cards,
  backgroundColor = 'bg-display-0',
  cardBackgroundColor = 'bg-display-100',
}: FeatureRTBsProps) {
  return (
    <section
      id={referId}
      className={twMerge(
        'flex flex-col justify-center items-center pb-40 md:pb-80 px-24 md:px-40 w-full',
        backgroundColor,
      )}
    >
      <div className="flex flex-col md:flex-row gap-24 md:gap-40 max-w-[455px] md:max-w-[1170px]">
        {cards.map(({ title, description, iconName }) => (
          <div
            className={twMerge(
              'flex-1 p-24 rounded-[8px]',
              cardBackgroundColor,
            )}
          >
            <Icon
              name={iconName}
              className="w-[40px] min-w-[40px] h-[40px] text-stone-500 fill-current"
            />
            <p className="mt-16 font-bold paragraph-18 md:paragraph-20">
              {title}
            </p>
            <p className="mt-8 paragraph-16">{description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
