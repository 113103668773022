import { ImageCMSProps } from '@/types/cms';
import { Player } from './Player';

export interface PrettyVideoProps {
  title: string;
  referId: string;
  videoUrl: string;
  thumbnail: ImageCMSProps;
  thumbnailQuality: number;
  description?: string;
  author?: string;
  caption?: string;
  backgroundColor?: string;
}

export function PrettyVideo(props: PrettyVideoProps) {
  const { referId, title, backgroundColor } = props;

  return (
    <section
      id={referId}
      className="w-full"
      style={{
        backgroundColor,
      }}
    >
      <div className="flex flex-col gap-24 lg:gap-40 py-40 lg:py-80 px-24 m-auto w-full max-w-7xl">
        <h2
          className="font-display text-center heading-4 lg:heading-3"
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <div className="overflow-hidden relative w-full h-full min-h-[max-content] max-h-[466px] md:max-h-[537px] lg:max-h-[629px] rounded-2xl lg:rounded-6xl transition-all h-max">
          <Player {...props} />
        </div>
      </div>
    </section>
  );
}
