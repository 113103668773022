import Image from 'next/image';

import { CardImageProps } from '@/types/cms';

type InfoCardImageProps = {
  referId: string;
  title: string;
  description: string;
  infoCards: CardImageProps[];
};

export function InfoCardImage({
  referId,
  title,
  description,
  infoCards,
}: InfoCardImageProps) {
  return (
    <div
      id={referId}
      className="flex flex-col gap-24 lg:gap-40 justify-center items-center py-40 lg:py-80 px-24 2xl:px-0"
    >
      <div className="flex flex-col gap-8 justify-center items-center">
        {title && (
          <h3
            dangerouslySetInnerHTML={{ __html: title }}
            className="font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-9 text-center"
          />
        )}
        {description && (
          <p
            dangerouslySetInnerHTML={{ __html: description }}
            className="font-sharon text-lg lg:text-xl leading-[26px] lg:leading-5 text-center"
          />
        )}
      </div>
      <div className="flex flex-col md:flex-row gap-16 lg:gap-24 justify-center md:justify-between items-center lg:items-stretch w-full max-w-7xl">
        {infoCards.map(({ image, description, title }, index) => (
          <div
            key={index}
            className="flex flex-col lg:flex-row gap-24 justify-center lg:justify-between items-center p-24 lg:p-40 lg:pr-0 w-full bg-gradient-to-t from-[#EFF4F8] to-white rounded-base border-2 border-display-100"
          >
            <div className="flex flex-col order-2 lg:order-none gap-4 justify-center lg:max-w-[234px]">
              <p className="font-display text-xl lg:text-4xl font-bold leading-5 lg:leading-8 text-display-900">
                {title}
              </p>
              <p className="text-lg lg:text-xl leading-[26px] lg:leading-[30px] text-display-900">
                {description}
              </p>
            </div>
            {image && (
              <div className="flex justify-center items-center md:w-full lg:w-[278px] ">
                <Image
                  src={image.path}
                  width={300}
                  height={227}
                  alt={image.alternativeText}
                  className="object-cover w-full h-[227px] lg:h-[256px] rounded-base lg:rounded-r-none lg:rounded-l-2xl"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
