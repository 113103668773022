'use client';

import { ChatConfigProps } from '@/app/hooks/useChat';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

const Widget = dynamic(() => import('@/components/Widget'), { ssr: false });

interface Props {
  config: ChatConfigProps;
}
export function ConditionalWidget({ config }: Props) {
  const pathname = usePathname() || '/';

  const shouldNotRenderChatWidget =
    !config.enabled || pathname === '/modostone' || pathname.includes('mgm');

  if (shouldNotRenderChatWidget) return null;

  return <Widget twilioReqSource="stone_widget_active_chat" />;
}
