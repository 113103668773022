import Image from 'next/image';

import { BannerProps } from '.';
import CardStoneEcosystem from './CardStoneEcosystem';

const BannerEcosystem = ({
  bannerWithDescriptions: { responsiveImage, descriptions },
}: Pick<BannerProps, 'bannerWithDescriptions'>) => {
  return (
    <div className="flex relative justify-center items-center w-full max-w-[1117px]">
      <div className="hidden lg:flex max-w-7xl">
        <Image
          src={responsiveImage[0].image.path}
          alt={responsiveImage[0].image.alternativeText}
          width={responsiveImage[0].width}
          height={responsiveImage[0].height}
          objectFit="cover"
          className={responsiveImage[0].className}
          layout="intrinsic"
        />
      </div>

      <div className="flex lg:hidden max-w-7xl">
        <Image
          src={responsiveImage[1].image.path}
          alt={responsiveImage[1].image.alternativeText}
          width={responsiveImage[1].width}
          height={responsiveImage[1].height}
          objectFit="cover"
          className={responsiveImage[1].className}
          layout="intrinsic"
        />
      </div>
      <div className="absolute p-16 m-auto w-full h-full">
        <div className="flex flex-col justify-center items-center w-full h-full rounded-2xl border-4 lg:border-[6px] border-stone-400 border-solid">
          <div className="relative top-[85px] lg:top-[53px] ml-[130px] lg:ml-[410px]">
            <div className="flex flex-col gap-6 lg:justify-between items-center lg:items-start w-[228px]">
              <div className="flex order-1 justify-center items-center w-20 h-20">
                <div className="w-full h-full bg-white rounded-full opacity-50" />
                <div className="absolute w-12 h-12 bg-white rounded-full" />
              </div>
              <CardStoneEcosystem>{descriptions[0].text}</CardStoneEcosystem>
            </div>
          </div>
          <div className="relative top-[105px] lg:top-[104px] ml-[420px] lg:ml-[590px]">
            <div className="flex flex-col gap-4 lg:justify-between items-center w-[228px]">
              <div className="flex order-1 justify-center items-center mr-[70px] lg:mr-0 w-20 h-20">
                <div className="w-full h-full bg-white rounded-full opacity-50" />
                <div className="absolute w-12 h-12 bg-white rounded-full" />
              </div>
              <CardStoneEcosystem>{descriptions[1].text}</CardStoneEcosystem>
            </div>
          </div>
          <div className="relative top-[55px] lg:top-[28px] mr-[345px] lg:mr-[635px] ">
            <div className="flex flex-col lg:flex-row gap-4 lg:justify-between w-[228px]">
              <div className="flex order-none lg:order-1 justify-center items-center ml-[100px] lg:ml-0 w-20 h-20">
                <div className="w-full h-full bg-white rounded-full opacity-50" />
                <div className="absolute w-12 h-12 bg-white rounded-full" />
              </div>
              <CardStoneEcosystem isPhone>
                {descriptions[2].text}
              </CardStoneEcosystem>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BannerEcosystem;
