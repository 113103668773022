'use client';

import { useChat } from '../hooks/useChat';
import { CMSEventParams, useAnalytics } from '../hooks/useAnalytics';
import { useEffect, useRef } from 'react';
import { ConditionalWidget } from '@/components/Widget/ConditionalWidget';

interface Props {
  chat: {
    enabled: boolean;
    delayInSeconds: number;
  };
  trackView?: CMSEventParams[];
}

export function PageScripts({ chat, trackView }: Props) {
  const { sendEvents } = useAnalytics();
  const hasTrackedView = useRef(false);

  useEffect(() => {
    if (!trackView) {
      console.warn('trackView is not defined or is false.');
      return;
    }

    if (!hasTrackedView.current) {
      sendEvents(trackView);
      hasTrackedView.current = true; // Mark as tracked
    }
  }, [sendEvents, trackView]);

  useChat(chat);

  return <ConditionalWidget config={chat} />;
}
