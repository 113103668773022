import Icon from '@/components/v3/Icon';
import { FeatureCardStonjiProps } from '@/types/cms';

import StonjiIcon from '../../base/ui/StonjiIcon';

export interface FeaturesBLockProps {
  referId: string;
  title: string;
  cards: FeatureCardStonjiProps[];
}

export function FeaturesBlock({ referId, title, cards }: FeaturesBLockProps) {
  return (
    <div
      id={referId}
      className="flex justify-center py-40 lg:py-80 px-24 mx-auto"
    >
      <div className="flex flex-col lg:flex-row gap-16 md:gap-24 lg:gap-40 justify-center items-center p-24 lg:p-40 max-w-[455px] md:max-w-7xl bg-display-100 rounded-2xl md:rounded-tr-none">
        <div className="flex flex-col lg:flex-row gap-16 md:gap-24 lg:gap-40 items-center">
          <h2
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            className="lg:max-w-[258px] font-display text-3xl lg:text-4xl font-bold leading-7 lg:leading-8 text-center lg:text-left"
          />
          <Icon
            name="chevron-down"
            className="lg:hidden min-w-[40px] min-h-[40px] text-stone-500 fill-current"
          />
          <Icon
            name="chevron-Right"
            className="hidden lg:block min-w-[40px] min-h-[40px] text-stone-500 fill-current"
          />
        </div>
        <div className="grid md:flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap grid-cols-2 gap-24 md:gap-40 md:mx-auto">
          {cards.map(({ title, description, stonjiName }) => (
            <div
              key={`feature-${title}`}
              className="flex flex-col flex-1 gap-16 lg:max-w-[192px]"
            >
              <StonjiIcon
                name={stonjiName}
                className="w-[64px] lg:w-[80px] h-[64px] lg:h-[80px]"
              />
              {title && (
                <p
                  className="font-bold paragraph-16 md:paragraph-18"
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              )}
              {description && (
                <p
                  className="paragraph-16"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
