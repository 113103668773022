import BannerEcosystem from './BannerEcosystem';
import { ResponsiveImagesWithAttributes } from '@/types/cms';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';

export type BannerProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  title: string;
  bannerWithDescriptions: {
    responsiveImage: ResponsiveImagesWithAttributes[];
    descriptions: { text: string }[];
  };
};

export function Banner({
  referId,
  forwardRef,
  title,
  bannerWithDescriptions,
}: BannerProps) {
  return (
    <section
      id={referId}
      className="hidden md:flex w-full h-full"
      ref={forwardRef}
    >
      <div className="hidden md:flex flex-col w-full h-full">
        <div className="w-full" />
        <div className="flex justify-center w-full h-[268px] lg:h-[344px]">
          <div className="flex gap-8 lg:gap-24 justify-center md:items-end p-40 lg:p-80 pt-80 lg:pt-[120px] max-w-[688px] lg:max-w-7xl bg-display-100 rounded-t-6xl">
            <h2 className="lg:pr-[40%] xl:pr-[50%] font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-[48px] text-display-900">
              {title}
            </h2>
          </div>
        </div>
        <div className="flex justify-center pb-40 m-auto w-full max-w-[688px] lg:max-w-7xl bg-display-100 rounded-b-6xl">
          <BannerEcosystem bannerWithDescriptions={bannerWithDescriptions} />
        </div>
      </div>
    </section>
  );
}
