'use client';

import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

/* eslint-disable react-hooks/rules-of-hooks */
import { Direction, useScrollContext } from '@/contexts/scroll';

type Props = {
  children: React.ReactNode;
  sections: SectionProp[];
  defaultSectionId: string;
};

export type UseInViewRefType = (node?: Element | null | undefined) => void;

type ChildType = {
  props?: {
    referId: string;
    useInViewRef: UseInViewRefType;
  };
};

type SectionState = {
  sectionId: string;
  index: number;
};

type SectionProps = {
  sectionId: string;
  forwardRef: (node?: Element | null | undefined) => void;
  elementRef: React.RefObject<HTMLDivElement>;
  activeSectionId: string;
};

export type SectionProp = {
  sectionId: string;
  sectionColor: string;
};

type AnimatedBackgroundProps = {
  section: SectionProp;
  lastSection: SectionState;
  activeSection: SectionState;
};

const AnimatedBackground: React.FC<AnimatedBackgroundProps> = ({
  section,
  lastSection,
  activeSection,
}) => {
  const { sectionId, sectionColor } = section;
  const isClass = sectionColor.startsWith('bg-');

  return (
    <div
      key={sectionId}
      className={clsx(
        `fixed top-0 left-0 z-[-1] w-[100dvw] h-[100dvh] transition-all duration-300`,
        isClass ? sectionColor : '',
        lastSection.sectionId === sectionId ? 'delay-200' : '',
      )}
      style={{
        ...(isClass ? {} : { background: sectionColor }),
        opacity: sectionId === activeSection.sectionId ? 1 : 0,
      }}
    />
  );
};

export function SectionsAnimatedBackground({
  children,
  sections,
  defaultSectionId,
}: Props) {
  const [activeSection, setActiveSection] = useState<SectionState>({
    sectionId: defaultSectionId,
    index: 0,
  });

  const [lastSection, setLastActiveSection] = useState<SectionState>({
    sectionId: defaultSectionId,
    index: 0,
  });

  return (
    <main>
      {sections.map(({ sectionId, sectionColor }) => {
        return (
          <AnimatedBackground
            key={sectionId}
            section={{
              sectionId: sectionId,
              sectionColor: sectionColor,
            }}
            activeSection={activeSection}
            lastSection={lastSection}
          />
        );
      })}
      {React.Children.map(children, (child, index) => {
        const { scrollDirection } = useScrollContext();

        const typedChild = child as ChildType;
        const sectionId = typedChild?.props?.referId as string;
        const elementRef = useRef<HTMLDivElement>(null);
        const [distanceFromTop, setDistanceFromTop] = useState<number>(0);

        const defaultUpThreshold = 0.8;

        const upThreshold =
          sectionId === sections[0].sectionId ? 0.4 : defaultUpThreshold;

        const [ref, inView] = useInView({
          threshold: scrollDirection === Direction.DOWN ? 0.1 : upThreshold,
          initialInView: false,
        });

        useEffect(() => {
          const handleScroll = () => {
            if (elementRef.current) {
              const { top } = elementRef.current.getBoundingClientRect();
              setDistanceFromTop(top);
            }
          };

          window.addEventListener('scroll', handleScroll);
          handleScroll();

          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);

        useEffect(() => {
          if (!inView) return;

          if (
            scrollDirection === Direction.UP &&
            sectionId === sections[3].sectionId &&
            distanceFromTop < 100
          ) {
            return;
          }

          setLastActiveSection(activeSection);

          const isTheSectionAboveTheActiveSectionOnScrollUp =
            scrollDirection === Direction.UP && activeSection.index > index;

          const isTheSectionBellowTheActiveSectionOnScrollDown =
            scrollDirection === Direction.DOWN && activeSection.index < index;

          if (
            isTheSectionAboveTheActiveSectionOnScrollUp ||
            isTheSectionBellowTheActiveSectionOnScrollDown
          ) {
            setActiveSection({
              sectionId: sectionId,
              index,
            });
          }
        }, [
          child,
          distanceFromTop,
          inView,
          index,
          scrollDirection,
          sectionId,
          typedChild,
        ]);

        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement<SectionProps>, {
            forwardRef: ref,
            elementRef: elementRef,
            activeSectionId: activeSection.sectionId,
          });
        }

        return child;
      })}
    </main>
  );
}
