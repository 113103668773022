import CompanyPartnersScrollingTicker from './CompanyPartnersScrollingTicker';
import { ResponsiveImagesWithAttributes } from '@/types/cms';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';

export type VerticalTickerBannerProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  title: string;
  description: string;
  leftTicker: ResponsiveImagesWithAttributes[];
  rightTicker: ResponsiveImagesWithAttributes[];
};

export function VerticalTickerBanner({
  referId,
  forwardRef,
  title,
  description,
  leftTicker,
  rightTicker,
}: VerticalTickerBannerProps) {
  return (
    <section id={referId} ref={forwardRef}>
      <div className="flex justify-center self-center">
        <div className="flex flex-col md:flex-row gap-[24px] lg:gap-[40px] justify-between items-center self-center px-[24px] sm:px-[40px] lg:px-[80px] my-[100px] xl:my-[80px] mx-[24px] w-[1169px] h-auto bg-display-100 rounded-md">
          <div className="flex flex-col gap-8 md:gap-16 sm:py-[40px] pt-[40px]">
            <h2
              className="font-display text-3xl lg:text-4xl font-bold leading-[120%]"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p className="text-lg lg:text-[18px] leading-[26px]">
              {description}
            </p>
          </div>
          <div>
            <CompanyPartnersScrollingTicker
              leftTicker={leftTicker}
              rightTicker={rightTicker}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
