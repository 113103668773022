import { Card, CardProps } from './Card';

export interface FeesBannerProps {
  id: number;
  referId: string;
  title: string;
  description: string;
  cards: CardProps[];
  analyticsSectionReference?: string;
}

export function FeesBanner({
  referId,
  title,
  cards,
  description,
}: FeesBannerProps) {
  return (
    <div
      id={referId}
      className="flex flex-col justify-center items-center py-40 px-16 md:px-24 lg:px-[auto] w-full"
    >
      <div className="flex flex-col gap-16 lg:gap-24 justify-center items-center py-24 lg:py-40 px-16 md:px-24 lg:px-40 w-full lg:w-min bg-display-100 rounded-2xl lg:rounded-6xl">
        <h3
          className="font-display lg:text-4xl text-center heading-3"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <div className="grid md:flex md:flex-row grid-cols-2 gap-16 lg:gap-24 justify-center items-center w-full">
          {cards.map((card, index) => (
            <Card key={card.title} {...card} id={index} />
          ))}
        </div>
        <p
          className="text-base leading-4 text-center"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </div>
  );
}
