import Icon from '@/components/v3/Icon';
import { FeatureCardIconProps } from '@/types/cms';

type CreditCardProps = {
  referId: string;
  title: string;
  cards: FeatureCardIconProps[];
};

export function CreditCard({ referId, title, cards }: CreditCardProps) {
  return (
    <div
      id={referId}
      className="flex flex-col gap-24 lg:gap-40 justify-center items-center py-40 lg:py-80 px-24 2xl:px-0"
    >
      {title && (
        <h3
          dangerouslySetInnerHTML={{ __html: title }}
          className="font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-9 text-center"
        />
      )}
      <div className="flex md:grid lg:flex flex-col lg:flex-row md:grid-cols-2 gap-16 lg:gap-40 justify-center lg:justify-between items-center lg:items-stretch w-full max-w-7xl">
        {cards.map(({ iconName, description, title }, index) => (
          <div
            key={index}
            className="flex flex-col gap-16 justify-center items-center p-24 lg:p-40 w-full bg-gradient-to-t from-[#EFF4F8] to-white rounded-md lg:rounded-2xl border-2 border-display-200"
          >
            <div className="min-w-max">
              <Icon
                name={iconName}
                className={`w-48 h-48 text-stone-500 ${
                  iconName === 'arrow-up-right' && 'fill-current'
                }`}
              />
            </div>
            <div className="flex flex-col gap-16 items-center">
              <p className="font-display text-3xl lg:text-4xl font-bold leading-7 lg:leading-8 text-center text-display-900">
                {title}
              </p>
              <p className="text-lg lg:text-xl leading-[26px] lg:leading-5 text-center text-display-900">
                {description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
