import OnDesktop from '@/components/MediaQueries/OnDesktop';
import OnMobile from '@/components/MediaQueries/OnMobile';
import OnTablet from '@/components/MediaQueries/OnTablet';
import { ImageCMSProps } from '@/types/cms';

import ServiceConsumerCard, {
  ServiceConsumerCardProps,
} from './components/ServiceConsumerCard';
import ServiceConsumerVideo from './components/ServiceConsumerVideo';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';

export type VideoCardsProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  title: string;
  cardTitleDescriptions: ServiceConsumerCardProps['cardTitleDescriptions'];
  responsiveVideo: {
    videoUrl: string;
    thumbnail: ImageCMSProps[];
  };
};

export function VideoCards({
  referId,
  forwardRef,
  title,
  cardTitleDescriptions,
  responsiveVideo,
}: VideoCardsProps) {
  return (
    <section id={referId} ref={forwardRef}>
      <div className="flex flex-col justify-center items-center w-full">
        <div className="w-[340px] md:w-max lg:w-[97%] 2xl:w-[1280px] bg-stone-500 rounded-[24px]">
          <div className="pt-[120px] md:pt-[80px] pr-[40px] md:pr-[40px] pb-[80px] md:pb-[40px] pl-[40px] md:pl-[40px] lg:pl-[108px]">
            <div className="flex xl:mr-[550px] xl:mb-[10px] w-[270px] md:w-[600px] xl:w-[500px]">
              <h2
                className="font-display text-4xl font-bold leading-[120%] text-white"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
          </div>

          <OnDesktop>
            <div className="flex p-24 pr-0 m-auto lg:w-[97%] 2xl:w-[1280px] xl:h-[596px] bg-stone-600 rounded-[24px]">
              <div className={'left-8 w-[100%]'}>
                <ServiceConsumerVideo
                  videoUrl={responsiveVideo.videoUrl}
                  classImage="w-[100%] p-[20px] xl:h-[548px] lg:h-[535px]"
                  classVideo="2xl-w-[1048px] xl:h-[548px] lg:h-[535px]"
                  img={responsiveVideo.thumbnail[2].path}
                />
              </div>
              <ServiceConsumerCard
                className="right-28"
                cardTitleDescriptions={cardTitleDescriptions}
              />
            </div>
          </OnDesktop>
          <OnTablet>
            <div className="p-16 m-auto bg-stone-600 rounded-[24px]">
              <div className="flex flex-col">
                <ServiceConsumerVideo
                  videoUrl={responsiveVideo.videoUrl}
                  width={630}
                  height={350}
                  img={responsiveVideo.thumbnail[1].path}
                />
              </div>
              <ServiceConsumerCard
                className="bottom-10"
                cardTitleDescriptions={cardTitleDescriptions}
              />
            </div>
          </OnTablet>
          <OnMobile>
            <div className="py-16 w-[340px] bg-stone-600 rounded-[24px]">
              <div className="flex flex-col">
                <ServiceConsumerVideo
                  videoUrl={responsiveVideo.videoUrl}
                  width={310}
                  height={210}
                  img={responsiveVideo.thumbnail[0].path}
                />
              </div>
              <ServiceConsumerCard
                className="bottom-10"
                cardTitleDescriptions={cardTitleDescriptions}
              />
            </div>
          </OnMobile>
        </div>
      </div>
    </section>
  );
}
