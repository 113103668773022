import Video from '@/components/v3/Video';
import { ImageCMSProps } from '@/types/cms';
import createSrcset from '@/utils/createSrcset';

import Button, { ButtonProps } from '../../base/ui/Button';
import styles from './VideoBanner.module.css';

export interface VideoBannerProps {
  referId: string;
  title: string;
  description: string;
  tag: string | null;
  url: string;
  image: ImageCMSProps;
  buttons?: ButtonProps[];
}

export function VideoBanner({
  referId,
  tag,
  title,
  description,
  url,
  image,
  buttons,
}: VideoBannerProps) {
  return (
    <div
      id={referId}
      className={`flex flex-col justify-center items-center py-40 md:py-80 px-24 md:px-40 w-full bg-stone-500`}
    >
      <div className="flex flex-col items-center w-full max-w-[1280px]">
        <div className="flex flex-col md:flex-row md:gap-40 justify-between items-center w-full max-w-[1170px]">
          {tag && (
            <p className="md:hidden w-full max-w-[455px] font-bold text-stone-700 uppercase paragraph-14 lg:paragraph-16">
              {tag}
            </p>
          )}
          <div className="overflow-hidden my-16 md:my-0 w-full max-w-[620px] max-h-[384px] rounded-md md:rounded-2xl">
            <Video
              imgSrcset={createSrcset(image.path)}
              img={image.path}
              id={url}
            />
          </div>
          <div className="w-full md:w-[50%]">
            {tag && (
              <p className="hidden md:block md:mb-16 font-bold text-stone-700 uppercase paragraph-14">
                {tag}
              </p>
            )}
            <h2
              className={`text-4xl font-bold font-display leading-8 text-display-0`}
            >
              {title}
            </h2>
            <p
              className={`mt-8 mb-[24px] text-display-0 paragraph-16 md:paragraph-18`}
            >
              {description}
            </p>
            <div className={styles.VideoCtaContainer}>
              <div className="flex flex-col md:flex-row gap-16 mt-16 md:mt-24 w-full">
                {buttons?.map((props, index) => (
                  <Button
                    key={`${props.label}-${index}`}
                    {...props}
                    sectionReference={title}
                    className="w-full md:w-max"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
