import Image from 'next/image';
import Link from 'next/link';

import ArrowUpRight from '@/components/v3/Icon/icons/ArrowUpRight';
import { ImageResponsiveCMSProps } from '@/types/cms';
import { ColorTypes, ColorValues } from '@/types/styles';

import { Fees, Items } from './Fees';
import LINKS from '@/constants/links';
import { If } from '@/components/UtilitiesComponents';

export interface Props {
  referId: string;
  title: string;
  link?: {
    path: string;
  };
  buttonLabel?: string;
  itens: Items[];
  images: ImageResponsiveCMSProps;
  theme?: {
    backgroundColorType?: ColorTypes;
    backgroundColorValue?: ColorValues;
  };
  analyticsSectionReference?: string;
}

interface RootPropsWithRelation {
  accordions_pannel: Props;
}

export function AccordionsPanel({
  accordions_pannel: {
    referId,
    title,
    itens,
    images,
    buttonLabel,
    link,
    theme = { backgroundColorType: 'display', backgroundColorValue: '50' },
  },
}: RootPropsWithRelation) {
  const ariaLabelLinkVerification = (buttonLabel?: string) => {
    if (buttonLabel) {
      return `Saiba mais sobre ${buttonLabel}`;
    }

    return 'Clique aqui para mais informações';
  };

  return (
    <div
      id={referId}
      className={`w-full bg-${theme.backgroundColorType}-${theme.backgroundColorValue}`}
    >
      <div className="flex justify-center items-center py-32 md:py-80 px-24 w-full">
        <div className="flex overflow-hidden flex-col lg:flex-row items-center lg:items-stretch md:max-w-[720px] lg:max-w-[1170px] md:rounded-6xl md:border md:border-display-300">
          <div className="hidden lg:flex overflow-hidden relative w-full max-w-[585px]">
            <Image
              src={images.web.path}
              width={720}
              height={878}
              alt={images.web.alternativeText}
              className="object-cover object-center w-full h-auto"
            />
          </div>

          <div className="hidden md:flex lg:hidden overflow-hidden w-full max-w-[455px] md:max-w-[720px] h-full max-h-[455px] md:max-h-[560px] rounded-2xl md:rounded-b-none">
            <Image
              src={images.tablet.path}
              width={720}
              height={560}
              alt={images.tablet.alternativeText}
              className="object-cover"
            />
          </div>

          <div className="flex md:hidden overflow-hidden w-full max-w-[455px] md:max-w-[720px] h-full max-h-[455px] md:max-h-[560px] rounded-2xl md:rounded-b-none">
            <Image
              src={images.mobile.path}
              width={455}
              height={455}
              alt={images.mobile.alternativeText}
              className="object-cover"
            />
          </div>

          <div className="flex-1 p-0 md:p-40 mt-24 md:mt-0 w-full lg:min-w-[500px] max-w-[455px] md:max-w-[720px] lg:max-w-none h-auto md:bg-display-0">
            <Fees title={title} items={itens} />

            <div className="flex gap-4 items-center pt-20">
              <If condition={Boolean(link?.path)}>
                <Link
                  href={`${LINKS.CLOUDINARY_URL}/${link?.path}`}
                  target="_blank"
                  className="font-semibold text-stone-500"
                  rel="noreferrer"
                  aria-label={ariaLabelLinkVerification(buttonLabel)}
                >
                  {buttonLabel}
                </Link>
                <ArrowUpRight fontWeight={'bold'} fill="#00A868" width={10} />
              </If>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
