import React from 'react';

const ArrowUpRight: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0408 1.46745C19.4313 1.85798 19.4313 2.49114 19.0408 2.88167L2.3741 19.5483C1.98357 19.9389 1.35041 19.9389 0.959885 19.5483C0.569361 19.1578 0.569361 18.5246 0.959885 18.1341L17.6266 1.46745C18.0171 1.07693 18.6502 1.07693 19.0408 1.46745Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666992 2.17456C0.666992 1.62228 1.11471 1.17456 1.66699 1.17456H18.3337C18.8859 1.17456 19.3337 1.62228 19.3337 2.17456V18.8412C19.3337 19.3935 18.8859 19.8412 18.3337 19.8412C17.7814 19.8412 17.3337 19.3935 17.3337 18.8412V3.17456H1.66699C1.11471 3.17456 0.666992 2.72685 0.666992 2.17456Z"
      />
    </svg>
  );
};

export default ArrowUpRight;
