import { ImageCMSProps } from '@/types/cms';
import Image from 'next/image';

export interface TFlag {
  image: ImageCMSProps;
}

interface Props {
  flags: TFlag[];
  title: string;
}

export function FlagsGroup({ flags, title }: Props) {
  return (
    <div className="mx-auto mb-16 !w-auto">
      <div className="flex flex-col items-center mt-[8px] whitespace-nowrap rounded-[1px] border border-b-0 border-display-300">
        <p
          className="px-4 mt-[-10px] font-sharon bg-display-0 paragraph-14"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>

      <div className="flex flex-wrap gap-8 justify-center pt-4">
        {flags.map(({ image }) => (
          <div
            key={image.alternativeText}
            className="relative !w-[45px] lg:!w-[56px] !h-[30px] lg:!h-[38px]"
          >
            <div className="relative w-full h-full">
              <Image
                src={image.path}
                alt={image.alternativeText}
                layout="fill"
                quality={80}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
