import Image from 'next/image';

import { ImageCMSProps } from '@/types/cms';

interface BestExperienceProps {
  title: string;
  stamps: {
    id: number;
    image: ImageCMSProps;
  }[];
}
export function BestExperience({
  bestExperience: { title, stamps },
}: {
  bestExperience: BestExperienceProps;
}) {
  return (
    <div className="flex justify-center items-center py-40 lg:py-80 px-24 2xl:px-0">
      <div className="flex flex-wrap lg:flex-nowrap gap-16 md:gap-40 justify-center items-center w-full max-w-[455px] md:max-w-7xl">
        <h2
          className="w-full lg:max-w-[465px] font-display text-xl md:text-2xl font-bold leading-5 lg:leading-6 text-center lg:text-left text-stone-500"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />

        <div className="flex md:grid lg:flex flex-col md:flex-row grid-cols-2 gap-16 md:gap-40 lg:gap-16 xl:gap-40 justify-center items-center w-full md:max-w-[383px] lg:max-w-[775px]">
          {stamps.map(({ image }) => (
            <div className="w-[179px] md:w-[179px] h-[70px] md:h-[70px]">
              <Image
                src={image.path}
                width={179}
                height={70}
                alt={image.alternativeText}
                quality={80}
                style={{ objectFit: 'contain' }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
