import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { widgetActiveChatIsOpenState } from '@/components/Widget/atoms';

export interface ChatConfigProps {
  enabled: boolean;
  delayInSeconds: number;
}
export function useChat(props: ChatConfigProps) {
  const [, setWidgetActiveChatIsOpenState] = useRecoilState(
    widgetActiveChatIsOpenState,
  );

  useEffect(() => {
    let timeout = undefined;

    if (props?.enabled) {
      timeout = setTimeout(
        () => setWidgetActiveChatIsOpenState(true),
        props.delayInSeconds * 1000,
      );
    }

    return () => clearTimeout(timeout);
  }, [props.delayInSeconds, props?.enabled, setWidgetActiveChatIsOpenState]);
}
