import Icon, { IconsNames } from '@/components/v3/Icon';
import { ResponsiveImagesWithAttributes } from '@/types/cms';
import Image from 'next/image';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';

type CheckImagesProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  elementRef?: React.RefObject<HTMLDivElement>;
  title: string;
  description: string;
  iconDescriptions: {
    iconName: IconsNames;
    description: string;
  }[];
  logoImages: ResponsiveImagesWithAttributes[];
};
export function CheckImages({
  referId,
  forwardRef,
  elementRef,
  title,
  description,
  iconDescriptions,
  logoImages,
}: CheckImagesProps) {
  return (
    <div ref={elementRef}>
      <section
        id={referId}
        ref={forwardRef}
        className="flex flex-col sm:flex-row gap-40 justify-center py-[80px] lg:py-[160px] bg-display-100"
      >
        <div className="flex flex-col md:justify-center md:p-32 px-24 lg:max-w-[700px] rounded-xl md:rounded-md">
          <h3
            className="font-display font-bold"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p className="mt-12 lg:mb-16 md:text-lg">{description}</p>

          <div className="hidden lg:flex flex-col gap-16">
            {iconDescriptions.map(item => (
              <div className="flex gap-8 items-center" key={item.description}>
                <Icon
                  name={item.iconName}
                  className="text-stone-500 fill-current"
                />
                <p className="text-lg">{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-16 items-center p-32 py-0 w-full md:w-full lg:w-auto h-auto lg:h-auto">
          {logoImages.map(item => (
            <div className="flex justify-center" key={item.image.path}>
              <Image
                src={item.image.path}
                alt={item.image.alternativeText}
                width={item.width}
                height={item.height}
                objectFit="fill"
                className={item.className}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
