import React from 'react';

import { Card, CardProps } from './Card';

interface Props {
  referId: string;
  title?: string;
  cards: CardProps[];
}

export function DefaultRTBs({ referId, title, cards }: Props) {
  return (
    <div
      id={referId}
      className="flex flex-col gap-24 lg:gap-40 justify-center items-center py-40 lg:py-80 px-24 2xl:px-0"
    >
      {title && (
        <h2
          dangerouslySetInnerHTML={{ __html: title }}
          className="font-display text-4xl lg:text-6xl font-bold leading-[38px] lg:leading-9 text-center"
        />
      )}

      <div className="flex md:grid lg:flex flex-col lg:flex-row md:grid-cols-2 gap-16 justify-center lg:justify-between items-center lg:items-stretch w-full max-w-7xl">
        {cards.map(props => (
          <Card {...props} key={props.title} />
        ))}
      </div>
    </div>
  );
}
