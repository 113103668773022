import Image from 'next/image';
import OnDesktop from '@/components/MediaQueries/OnDesktop';
import OnMobile from '@/components/MediaQueries/OnMobile';
import OnTablet from '@/components/MediaQueries/OnTablet';
import { ResponsiveImagesWithAttributes } from '@/types/cms';

import { Items } from './Items';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';

export type TextDescriptionFormProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  title: string;
  description: string;
  buttonLabel: string;
  responsiveImage: ResponsiveImagesWithAttributes[];
};

export function TextDescriptionForm({
  referId,
  forwardRef,
  title,
  description,
  buttonLabel,
  responsiveImage,
}: TextDescriptionFormProps) {
  return (
    <div ref={forwardRef} id={referId}>
      <div className="flex justify-center px-24 w-full bg-display-100">
        <div className="flex flex-col gap-24 justify-center items-center pt-80 pb-80 w-full max-w-7xl">
          <OnMobile>
            <div className="flex flex-col gap-16 items-center p-24 text-center bg-white rounded-3xl">
              <div className="flex overflow-hidden flex-1 rounded-[16px] border-white">
                <Image
                  src={responsiveImage[0].image.path}
                  width={responsiveImage[0].width}
                  height={responsiveImage[0].height}
                  alt={responsiveImage[0].image.alternativeText}
                  layout="intrinsic"
                />
              </div>
              <Items
                title={title}
                description={description}
                buttonLabel={buttonLabel}
              />
            </div>
          </OnMobile>
          <OnTablet>
            <div className="flex gap-16 p-24 max-w-[720px] bg-white rounded-3xl">
              <div className="flex-1">
                <Items
                  title={title}
                  description={description}
                  buttonLabel={buttonLabel}
                />
              </div>
              <div className="flex overflow-hidden flex-1 max-h-[420px] rounded-[16px] border-white">
                <Image
                  src={responsiveImage[1].image.path}
                  width={responsiveImage[1].width}
                  height={responsiveImage[1].height}
                  alt={responsiveImage[1].image.alternativeText}
                  layout="intrinsic"
                />
              </div>
            </div>
          </OnTablet>
          <OnDesktop>
            <div className="flex gap-32 p-40 max-w-[950px] bg-white rounded-3xl">
              <div className="flex-1">
                <Items
                  title={title}
                  description={description}
                  buttonLabel={buttonLabel}
                />
              </div>
              <div className="flex overflow-hidden flex-1 max-h-[496px] bg-white rounded-[16px] border-white">
                <Image
                  src={responsiveImage[2].image.path}
                  width={responsiveImage[2].width}
                  height={responsiveImage[2].height}
                  alt={responsiveImage[2].image.alternativeText}
                  objectFit="cover"
                  layout="intrinsic"
                  className="object-cover"
                />
              </div>
            </div>
          </OnDesktop>
        </div>
      </div>
    </div>
  );
}
