import { FeatureCardStonjiProps } from '@/types/cms';

import StonjiIcon from '../../base/ui/StonjiIcon';

export interface FeaturesHeroProps {
  referId: string;
  cards: FeatureCardStonjiProps[];
}

export function FeaturesHero({ referId, cards }: FeaturesHeroProps) {
  return (
    <div
      id={referId}
      className="flex lg:gap-40 justify-center items-center py-40 px-24 2xl:px-0 w-full bg-display-100 md:bg-display-50"
    >
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-24 w-full max-w-[455px] md:max-w-screen-xl">
        {cards?.map(({ title, stonjiName, description }) => {
          return (
            <div
              key={`feature-${title}`}
              className="flex gap-24 w-full md:max-w-[348px] lg:max-w-[290px]"
            >
              <StonjiIcon
                name={stonjiName}
                className="w-64 min-w-[64px] h-64 min-h-[64px]"
              />
              <div className="">
                {title && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                    className="md:mb-4 text-[18px] lg:text-[20px] font-bold text-stone-500"
                  />
                )}
                {description && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
