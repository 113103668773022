import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';
import { ImageResponsiveCMSProps } from '@/types/cms';

import style from './Partner.module.css';

interface Props {
  referId: string;
  title?: string;
  partners: ImageResponsiveCMSProps[];
}

export function Partners({ referId, partners, title }: Props) {
  return (
    <div id={referId} className="flex justify-center px-24 w-full bg-display-0">
      <div className="flex flex-col gap-24 justify-center items-center pt-40 pb-40 lg:pb-80 w-full max-w-7xl">
        {title && (
          <h2
            className="font-display text-3xl lg:text-6xl font-bold leading-6 lg:leading-9 text-center"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}

        <div
          className={classNames(
            'hidden lg:flex flex-wrap sm:gap-x-[110px] md:gap-x-0 justify-between md:py-32 w-full h-[200px] md:h-auto',
            style.Container,
          )}
        >
          {partners.map(
            ({ web: { path, alternativeText, width, height }, webQuality }) => {
              return (
                <div className="flex items-center" key={path}>
                  <div className="block">
                    <Image
                      src={path}
                      width={width}
                      height={height}
                      alt={alternativeText}
                      quality={webQuality || 70}
                    />
                  </div>
                </div>
              );
            },
          )}
        </div>

        <div
          className={classNames(
            'lg:hidden flex flex-wrap sm:gap-x-[110px] md:gap-x-0 justify-between md:py-32 w-full h-[200px] md:h-auto',
            style.Container,
          )}
        >
          {partners.map(
            ({
              mobile: { path, alternativeText, width, height },
              mobileQuality,
            }) => {
              return (
                <div className={`flex items-center ${style.item} `} key={path}>
                  <div className="block">
                    <Image
                      src={path}
                      width={width}
                      height={height}
                      alt={alternativeText}
                      quality={mobileQuality || 70}
                    />
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
    </div>
  );
}
