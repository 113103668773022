type CardEcosystem = {
  children: React.ReactNode;
  isPhone?: boolean;
};

const CardStoneEcosystem: React.FC<CardEcosystem> = ({ children, isPhone }) => {
  return (
    <div
      className={`flex justify-center items-center ${
        isPhone
          ? 'w-[156px] h-[94px] lg:w-[199px] lg:h-[72px]'
          : ' w-[199px] h-[72px]'
      }`}
    >
      <div className="w-full h-full bg-white rounded-xs opacity-50" />
      <div
        className={`flex absolute gap-8 justify-center items-center p-8 ${
          isPhone ? 'w-[148px] h-[88px] lg:w-[191px] lg:h-64' : 'w-[191px] h-64'
        } bg-white rounded-[8px]`}
      >
        <p className="text-base font-semibold leading-4 text-center text-display-800">
          {children}
        </p>
      </div>
    </div>
  );
};

export default CardStoneEcosystem;
