import Image from 'next/image';

import { ImageCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';

interface MutedBannerProps {
  referId: string;
  tag: string;
  title: string;
  description: string;
  image: ImageCMSProps;
  buttons: ButtonProps[];
}

export function MutedBanner({
  referId,
  image,
  tag,
  title,
  description,
  buttons,
}: MutedBannerProps) {
  return (
    <section
      id={referId}
      className="flex justify-center py-40 md:py-80 px-24 w-full bg-display-0"
    >
      <div className="max-w-full md:max-w-screen-xl bg-display-100 rounded-6xl">
        <div
          data-container="featureSoft"
          className={`relative flex flex-col items-center px-24 py-40 md:flex-row md:items-start md:justify-center lg:items-center lg:p-56 lg:py-80`}
        >
          <div className="flex overflow-hidden my-16 md:my-0 md:mr-24 lg:mr-40 rounded-md md:rounded-2xl">
            <Image
              src={image.path}
              alt={image.alternativeText}
              width={455}
              height={455}
              objectFit="contain"
            />
          </div>
          <div className="w-full md:w-[455px] max-w-[455px] md:max-w-full">
            {tag && (
              <p
                className="mb-16 font-bold text-stone-700 uppercase paragraph-16"
                dangerouslySetInnerHTML={{
                  __html: tag,
                }}
              />
            )}
            <h3
              className="md:mt-16 mb-8 font-display text-3xl lg:text-4xl font-bold leading-7 lg:leading-8"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            {description && (
              <p
                className="mt-8 paragraph-16 lg:paragraph-18"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            <div className="flex flex-col">
              {buttons.length > 0 && (
                <div className="flex flex-col md:flex-row gap-16 mt-16 md:mt-24 w-full">
                  {buttons?.map((props, index) => (
                    <Button
                      key={`${props.label}-${index}`}
                      {...props}
                      sectionReference={title}
                      className="w-full md:w-max"
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
