import Icon from '@/components/v3/Icon';
import { FeatureCardIconProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';

interface FinancialPartnerProps {
  referId: string;
  title: string;
  cards: FeatureCardIconProps[];
  buttons: ButtonProps[];
}
export function FeatureLine({
  referId,
  title,
  cards,
  buttons,
}: FinancialPartnerProps) {
  return (
    <div
      id={referId}
      className="flex justify-center items-center py-40 lg:py-80 px-24"
    >
      <div className="flex flex-col justify-center items-center w-full max-w-[455px] md:max-w-[950px]">
        <h2
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          className="font-display text-center heading-3"
        />

        <div className="flex flex-wrap gap-16 justify-center md:justify-between p-16 md:p-40 mt-16 md:mt-24 w-full rounded-2xl border-2 border-stone-500">
          {cards?.map(({ iconName, title, description }, index) => (
            <div
              className="flex flex-col gap-16 justify-center items-center first:w-full md:w-full xxs:w-[calc(50%-16px)] md:max-w-[170px] lg:max-w-[250px]"
              key={index}
            >
              <Icon
                name={iconName}
                className="w-40 h-40 text-stone-500 fill-current"
              />
              {title && (
                <h6
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                  className="font-semibold text-center paragraph-16 md:paragraph-18"
                />
              )}
              {description && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                  className="text-center paragraph-16 md:paragraph-18"
                />
              )}
            </div>
          ))}
        </div>
        {buttons.length > 0 && (
          <div className="flex flex-col md:flex-row gap-16 md:gap-24 mt-24 w-full md:w-max">
            {buttons?.map((props, index) => (
              <Button
                key={`${props.label}-${index}`}
                {...props}
                sectionReference={title}
                className="w-full md:w-max"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
