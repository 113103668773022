import Icon, { IconsNames } from '@/components/v3/Icon';
import { UseInViewRefType } from '../../base/cms/AnimatedBackground';

type IconDescriptionProps = {
  referId: string;
  forwardRef?: UseInViewRefType;
  iconsDescriptions: {
    iconName: IconsNames;
    description: string;
  }[];
};

export function IconDescription({
  referId,
  forwardRef,
  iconsDescriptions,
}: IconDescriptionProps) {
  return (
    <section>
      <div id={referId} className="pt-20 pb-80 bg-stone-900" ref={forwardRef}>
        <div className="flex flex-wrap gap-16 justify-center m-28">
          {iconsDescriptions.map(({ iconName, description }) => (
            <div key={description} className="flex gap-10 items-center">
              <Icon name={iconName} className="text-stone-500 fill-current" />
              <p className="font-semibold leading-[24px] text-white paragraph-16">
                {description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
