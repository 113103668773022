import { sectionsMap } from './sections.map';

import { SectionProps, SectionRules } from './SectionRules';

type TypedSectionIds = keyof typeof sectionsMap;

export function Section(props: SectionProps) {
  const component = sectionsMap[
    props.name as TypedSectionIds
  ] as unknown as React.FC<SectionProps>;

  return <SectionRules component={component} props={props} />;
}
