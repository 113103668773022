import Image from 'next/image';
import React from 'react';

import { ImageCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';

export interface NeedHelpProps {
  referId: string;
  title: string;
  description: string;
  image: ImageCMSProps;
  buttons: ButtonProps[];
}

export function NeedHelp({
  referId,
  title,
  description,
  image,
  buttons,
}: NeedHelpProps) {
  return (
    <section id={referId} className="flex text-display-0 bg-stone-600">
      <div className="flex flex-col md:flex-row justify-center items-center p-40 md:p-80 px-24 md:px-24 mx-auto w-full max-w-screen-xl">
        <Image
          src={image.path}
          alt={image.alternativeText}
          width={400}
          height={326}
        />

        <div
          className="flex flex-col items-center md:items-start md:ml-40"
          style={{ maxWidth: 400 }}
        >
          <h2
            className="mb-8 font-display text-center md:text-left text-current heading-2"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />

          <p className="text-center md:text-left text-current paragraph-16 md:paragraph-18">
            {description}
          </p>
          <div className="flex flex-col sm:flex-row items-center mt-24 w-full sm:w-auto">
            {buttons.length > 0 && (
              <div className="flex flex-col md:flex-row gap-16 mt-16 md:mt-24 w-full">
                {buttons?.map((props, index) => (
                  <Button
                    key={`${props.label}-${index}`}
                    {...props}
                    sectionReference={title}
                    className="w-full md:w-max"
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
