import { FlagsGroup } from './FlagsGroup';
import { ImageCMSProps } from '@/types/cms';

interface Props {
  flagsCollectionType: {
    referId: string;
    title: string;
    flagsGroup: Array<{
      title: string;
      flags: Array<{
        image: ImageCMSProps;
      }>;
    }>;
  };
}

export function Flags({
  flagsCollectionType: { referId, title, flagsGroup },
}: Props) {
  return (
    <section
      id={referId}
      className="flex flex-col justify-center items-center bg-display-0"
    >
      <div className="flex flex-col justify-center items-center py-40 px-16 md:px-24 w-full md:max-w-[1195px]">
        <h2
          className="font-display text-2xl md:text-xl lg:text-2xl font-bold leading-6 lg:leading-6 text-center"
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <div className="flex gap-8 mt-24 w-[310px] sm:w-[630px] md:w-[734px] lg:w-full">
          <FlagsGroup flags={flagsGroup[0].flags} title={flagsGroup[0].title} />
        </div>

        <div className="flex flex-col gap-8 lg:gap-40 justify-center w-[310px] sm:w-[630px] md:w-[681px] lg:w-full">
          <FlagsGroup flags={flagsGroup[1].flags} title={flagsGroup[1].title} />
        </div>
      </div>
    </section>
  );
}
