import Image from 'next/image';

import { ImageCMSProps } from '@/types/cms';

import Button, { ButtonProps } from '../../base/ui/Button';

interface SlimCardProps {
  referId: string;
  tag: string;
  title: string;
  description: string;
  image: ImageCMSProps;
  buttons: ButtonProps[];
}

export function SlimCard({
  referId,
  tag,
  title,
  description,
  image,
  buttons,
}: SlimCardProps) {
  return (
    <section
      id={referId}
      className="flex justify-center py-40 md:py-80 px-24 w-full bg-display-0"
    >
      <div className="w-full md:max-w-[1170px] bg-display-100 rounded-6xl">
        <div className="flex flex-col md:flex-row gap-24 lg:gap-40 justify-center items-center p-24 lg:p-40">
          <div className="flex overflow-hidden w-full md:max-w-[240px] lg:max-w-[448px]">
            <Image
              src={image.path}
              alt={image.alternativeText}
              width={image.width}
              height={image.height}
              quality={80}
              className=" object-cover"
              layout="responsive"
            />
          </div>
          <div className="w-full">
            {tag && (
              <p
                className="mb-16 font-bold text-stone-700 uppercase paragraph-16"
                dangerouslySetInnerHTML={{
                  __html: tag,
                }}
              />
            )}
            <h2
              className="px-24 md:px-0 md:mt-16 mb-8 font-display text-3xl md:text-4xl lg:text-6xl font-bold leading-7 md:leading-[38px] lg:leading-9 text-center md:text-left"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            {description && (
              <p
                className="mt-8 paragraph-16 lg:paragraph-18"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
            <div className="flex flex-col gap-16 mt-16">
              {buttons?.map((props, index) => (
                <Button
                  key={`${props.label}-${index}`}
                  {...props}
                  sectionReference={title}
                  className="w-full md:w-max"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
