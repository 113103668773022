import React from 'react';
import Button, { ButtonProps } from '../../base/ui/Button';

interface Props {
  referId: string;
  title: string;
  buttons: ButtonProps[];
}

export function BasicBand({ referId, title, buttons }: Props) {
  return (
    <section
      id={referId}
      className="py-40 md:py-80 px-24 w-full bg-display-100"
    >
      <div className="flex flex-col justify-center mx-auto w-full max-w-[455px] md:max-w-none lg:max-w-[840px]">
        <h2
          dangerouslySetInnerHTML={{ __html: title }}
          className="font-display text-4xl lg:text-6xl font-bold leading-8 lg:leading-9 text-center"
        />
        <div className="flex flex-col md:flex-row gap-16 justify-center mt-24">
          {buttons.map(button => (
            <Button key={button.label} {...button} />
          ))}
        </div>
      </div>
    </section>
  );
}
