import Icon from '@/components/v3/Icon';
import { FeatureCardIconProps } from '@/types/cms';

interface FeaturesInfoProps {
  referId: string;
  cards?: FeatureCardIconProps[];
  background?: boolean;
}

export function FeaturesInfo({
  referId,
  cards,
  background = false,
}: FeaturesInfoProps) {
  return (
    <section
      id={referId}
      className={`flex justify-center items-center p-24 py-40 w-full h-auto ${
        background ? 'bg-display-50' : ''
      }`}
    >
      <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-24 items-start w-full max-w-screen-xl h-full">
        {cards &&
          cards.map(({ iconName, title, description, tag }, index) => (
            <div className="flex gap-16 xl:w-[290px]" key={index}>
              <div>
                <Icon
                  name={iconName}
                  className="w-[64px] h-[64px] text-stone-50 fill-current"
                />
              </div>
              <div className="flex flex-col gap-4 justify-between h-full">
                <h6 className="font-display text-xl font-bold leading-5">
                  {title}
                </h6>
                <p className="text-lg leading-[26px]">
                  {description}
                  {tag && <span className="font-bold">{tag}</span>}
                </p>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}
