export interface CardProps {
  id: number;
  referId: string;
  title: string;
  value: string;
}

export function Card({ id, title, value }: CardProps) {
  return (
    <div
      className={`flex flex-col gap-8 w-full items-center justify-center rounded-[8px] lg:rounded-md bg-display-0 lg:w-[292px] p-16 lg:p-40 ${
        id === 0 && 'col-span-2'
      }`}
    >
      <p
        className="font-semibold text-center paragraph-14 lg:paragraph-16"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <div className="w-full h-auto text-4xl lg:text-6xl font-bold leading-8 text-center text-stone-500">
        {value}
      </div>
    </div>
  );
}
