export type ServiceConsumerCardProps = {
  className: string;
  cardTitleDescriptions: {
    description: string;
    title: string;
    subdescription: string;
  }[];
};
const ServiceConsumerCard = ({
  className,
  cardTitleDescriptions,
}: ServiceConsumerCardProps) => {
  return (
    <div
      className={`flex relative z-10 lg:flex-col flex-wrap gap-8 lg:gap-24 md:gap-12 justify-center ${className}`}
    >
      <div className="flex flex-col gap-8 md:gap-8 lg:gap-8 justify-center p-8 md:p-24 lg:p-24 w-[145px] md:min-w-[198px] h-[130px] md:h-[156px] bg-white rounded-t-[16px] rounded-l-[16px]">
        <p className="flex flex-col gap-[12px] md:gap-[18px] lg:gap-[18px] text-center paragraph-16 md:paragraph-18 lg:paragraph-18">
          {cardTitleDescriptions[0].description}
          <strong className="flex flex-col text-[36px] md:text-[48px] lg:text-[48px] font-bold text-center">
            {cardTitleDescriptions[0].title}
          </strong>
          {cardTitleDescriptions[0].subdescription}
        </p>
      </div>

      <div className="flex flex-col gap-8 md:gap-8 lg:gap-8 justify-center items-center p-8 md:p-24 lg:p-24 w-[145px] md:min-w-[198px] h-[130px] md:h-[156px] bg-white rounded-tl-[16px] rounded-br-[16px]">
        <p className="flex flex-col gap-[12px] md:gap-[18px] lg:gap-[18px] text-center paragraph-16 md:paragraph-18 lg:paragraph-18">
          {cardTitleDescriptions[1].description}
          <strong className="flex flex-col text-[36px] md:text-[48px] lg:text-[48px] font-bold text-center">
            {cardTitleDescriptions[1].title}
          </strong>
          {cardTitleDescriptions[1].subdescription}
        </p>
      </div>

      <div className="flex flex-col gap-8 md:gap-8 lg:gap-8 justify-center items-center p-8 md:p-24 lg:p-24 mx-8 md:mx-0 w-[300px] md:w-[198px] h-[130px] md:h-[156px] bg-white rounded-b-[16px] rounded-l-[16px]">
        <p className="flex flex-col gap-[12px] md:gap-[18px] lg:gap-[18px] text-center paragraph-16 md:paragraph-18 lg:paragraph-18">
          {cardTitleDescriptions[2].description}
          <strong className="flex flex-col text-[36px] md:text-[48px] lg:text-[48px] font-bold text-center">
            {cardTitleDescriptions[2].title}
          </strong>
          {cardTitleDescriptions[2].subdescription}
        </p>
      </div>
    </div>
  );
};
export default ServiceConsumerCard;
